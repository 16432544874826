/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

#muuzbox,
#muuzbox-inner {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 100px;
  height: 100px;
  z-index: 1000;
  overflow: hidden;
  &.active {
    position: fixed;
    // inset: 32px;
    height: calc(100% - 32px);
    width: 24rem;
    // height: 5rem;
    max-width: 100vw;
    max-height: 100dvh;
    margin: auto;
    bottom: 32px;
    right: 32px;
    top: 32px;
    overflow: hidden;
  }

  .close-muuzbox-btn {
    display: none;
    &.active {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1001;
    }
  }

  .minimise-muuzbox-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1001;
    svg {
      color: #000;
    }
    &.hidden {
      display: none;
    }
  }

  input[type="text"] {
    
    background-color: #F3F4F6;
  }
}

.App {
  height: unset;
}

.jukebox-panel {
  padding: 1.7rem 1.5rem 1.5rem 1.5rem;
}

.jukebox-divider {
  border-bottom: #aaa 1px solid !important;
}

.panel-fade {
  --bg-opacity: 0.35 !important;
  background-color: (247, 250, 252, var(--bg-opacity));
  // height: 33%;
}

.chatlist-carousel-container {
  display: relative;
  z-index: 30;
}

.outer-container {
  max-width: 800px;
  margin: auto;
  display: flex;
  // border: 1px solid #ccc;
  // border-radius: 5px;
  // box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  min-width: unset;
}

.outer-container > div {
  flex: 1;
}

.outer-container .carousel-wrapper {
  background: var(--orange);
  display: flex;
  flex-direction: column;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
}

.outer-container .content {
  background: var(--white);
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--white);
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 0 10px;
  max-width: 50px;
  border-radius: 30px;
  color: #000;
  // background: var(--orange);
  // border: 2px solid var(--white);
  &.next {
    transform: rotate(90deg);
  }
  &.prev {
    transform: rotate(-90deg);
  }
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}
.carousel-button path {
  fill: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.wc-chatlist {
  width: 5rem;
}

.carousel-inner {
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 250px;
  height: 170px;
  margin-left: 4px;
  text-align: left;
}

.carousel-inner::before,
.carousel-inner::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
  // background: linear-gradient(
  //   0deg,
  //   hsla(0, 0%, 100%, 0) 0%,
  //   hsla(27, 100%, 48%, 1) 100%
  // );
}

.carousel-inner::after {
  bottom: 0;
  // background: linear-gradient(
  //   0deg,
  //   hsla(27, 100%, 48%, 1) 0%,
  //   hsla(0, 0%, 100%, 0) 100%
  // );
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 112px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.carousel-item.visible {
  opacity: 1;
}

.muuzbox-wc-logo {
  width: 60px;
  height: 60px;
  background: url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fmuuzbox-logo.png?alt=media&token=e0f887ad-e97a-4ebc-97cc-2d4fa0d79b1d)
    center center;
  background-size: cover;
  margin-top: 4px;
  transition: 0.5s;
  &.active {
    width: 40px;
    height: 40px;
  }
}

.dj-wc-logo {
  width: 60px;
  height: 60px;
  background: url(https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fdj-obah.png?alt=media&token=44c52529-8bff-43a0-a646-b7a94b59a5ea)
    center center;
  background-size: cover;
  margin-top: 4px;
}

.muuz-confirm-panel {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-width: 100%;
  // max-height: 100%;
  // max-height: 950px;
  width: 80%;
  height: 50cqb;
  // opacity:0;
  z-index: 40;
  // transition: 0.5s;
  padding: 16px;
  // zoom: 150%;
}

.thredotmenu {
  width: 20px;
  height: 20px;

  background-image: radial-gradient(circle, black 2px, transparent 3px);
  // @media (prefers-color-scheme: dark) {
  //   background-image: radial-gradient(circle, white 2px, transparent 3px);
  // }
  background-size: 100% 33.33%;
}

.hamburger-menu {
  width: 15px;
  height: 3px;
  background-color: black;
  margin: 2px 0;
}

.modal-container {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  font-weight: bold;
  color: #62626e;
}

.modal-content {
  width: 80%;
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #d8dce3;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: #f7f8fa;
}

.modal-header {
  padding: 9px 10px;
  padding-left: 20px;
  height: 38px;
  font-size: 15px;
  position: relative;
}

.cross-btn {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.modal-body {
  flex-grow: 1;
  border-top: solid 1px #d8dce3;
  padding: 14px 29px 14px 19px;
}

.modal-footer {
  border-top: solid 1px #d8dce3;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
}

.close-btn {
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px 0px;
  background-color: rgb(77, 144, 255);
  color: rgb(247, 248, 250);
  height: 35px;
  width: 117px;
  font-size: 14px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(43, 123, 255);
  cursor: pointer;
}

.sidebar {
  //width: 280px;
  //min-height: 100vh;
  //box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  //background-color: #fff;
  //position: fixed;
  //top: 0;
  //left: -100%;
  width: 6rem;
  z-index: 1;
  transition: 0.5s;
}
.sidebar.active {
  //left: 0;
  width: 5rem;
}

.mobile-chatlist {
  margin-top: 2.5rem !important;
  // height: 500px;
}

.mobile-w-full {
  margin-left: 32px;
}

.thredotmenu-container {
  // margin-bottom: 1rem;
  // margin-left: 16px;
}

.text-enter-fit {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 10;
}

// .text-enter-fit {
//   position: relative;
//   width: 100%;
//   transition: 0.5s;
// }

// .text-enter-fit.text-enter-full {
//   position: fixed;
//   bottom: 5px;
//   right: 5px;
//   width: calc(100% - 265px);
// }

// width of input field
.text-enter-fit,
.text-enter-full {
  width: 360px;
}

.right--10 {
  right: -10rem;
}
// .chat-window-container {
//   height: calc(100vh - 220px);
// }
// .chat-window {
//   // dunno
//   //height: calc(100% - 88px);
//   height: calc(100vh - 300px) !important;
// }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .notmobile {
    display: none;
  }
  .mobile {
    display: block;
  }

  .menu-open {
    position: relative;
    display: block;
    left: auto;
    opacity: 1;
    transition: 0.5s;
  }

  .menu-open.menu-collapse {
    position: relative;
    left: -100px;
  }

  .menu-open.menu-fade {
    opacity: 0;
  }

  .menu-open.menu-fade2 {
    display: none;
    transition-delay: 5s;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .notmobile {
    display: none;
  }
  .mobile {
    display: block;
  }

  .mobile-w-full {
    margin-left: unset;
  }

  .thredotmenu-container {
    margin-left: unset;
  }
  .chatlist {
    display: block;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .right--10 {
    right: unset;
  }
  .chatlist {
    display: none;
  }
  //   .notmobile {
  //     display: block;
  //   }
  //   .mobile {
  //     display: none;
  //   }

  //   .menu-open {
  //     position: relative;
  //     left: auto;
  //     transition: 0.5s;
  //   }

  //   .menu-open.menu-collapse {
  //     position: relative;
  //     left: -100px;
  //   }
}

// @media only screen and (max-width: 768px) {

//   .notmobile {
//     display: block;
//   }

//   .mobile {
//     display: none;
//   }

//   .menu-openx {
//     position: relative;
//     left: auto;
//     transition: 0.5s;
//   }

//   .menu-openx.menu-collapsex {
//     position: relative;
//     left: -100px;
//   }
// }

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .notmobile {
//     display: block;
//   }
//   .mobile {
//     display: none;
//   }
// }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile {
    display: none;
  }
  .notmobile {
    display: block;
  }
  //   .text-enter-fit.text-enter-full {
  //     position: unset;
  //     bottom: unset;
  //     left: unset;
  //     width: unset;
  //   }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mobile {
    display: none;
  }
  .notmobile {
    display: block;
  }
}
